const LIST_ALL_TYPES = [
    { name: 'Conselho Regional de Medicina', code: 'CRM', typeRegistry: '9' },
    { name: 'Conselho Regional de Psicologia', code: 'CRP', typeRegistry: '72' },
    { name: 'Conselho Regional de Odontologia', code: 'CRO', typeRegistry: '77' },
    { name: 'Conselho Regional de Fisioterapia e Terapia Ocupacional', code: 'CREFITO', typeRegistry: '70' },
    { name: 'Conselho Regional de Fonoaudiologia', code: 'CREFONO', typeRegistry: '8' },
    { name: 'Conselho Regional de Nutrição', code: 'CRN', typeRegistry: '13' },
    { name: 'Conselho Regional de Educação Física', code: 'CREF', typeRegistry: '6' },
    { name: 'Conselho Regional de Enfermagem', code: 'COREN', typeRegistry: '48' },
    { name: 'Minha atividade não exige conselho', code: 'OUTRO', typeRegistry: '53' },
]

const LIST_ALL_STATES = [
    { name: 'AC', code: 'AC' },
    { name: 'AL', code: 'AL' },
    { name: 'AP', code: 'AP' },
    { name: 'AM', code: 'AM' },
    { name: 'BA', code: 'BA' },
    { name: 'CE', code: 'CE' },
    { name: 'DF', code: 'DF' },
    { name: 'ES', code: 'ES' },
    { name: 'GO', code: 'GO' },
    { name: 'MA', code: 'MA' },
    { name: 'MT', code: 'MT' },
    { name: 'MS', code: 'MS' },
    { name: 'MG', code: 'MG' },
    { name: 'PA', code: 'PA' },
    { name: 'PB', code: 'PB' },
    { name: 'PR', code: 'PR' },
    { name: 'PE', code: 'PE' },
    { name: 'PI', code: 'PI' },
    { name: 'RJ', code: 'RJ' },
    { name: 'RN', code: 'RN' },
    { name: 'RS', code: 'RS' },
    { name: 'RO', code: 'RO' },
    { name: 'RR', code: 'RR' },
    { name: 'SC', code: 'SC' },
    { name: 'SP', code: 'SP' },
    { name: 'SE', code: 'SE' },
    { name: 'TO', code: 'TO' },
]
  



export default {
    LIST_ALL_TYPES,
    LIST_ALL_STATES,

}